@import "../../styles/gw-colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$secondary: theme-color("green");
$light-gray: theme-color("white");
$gray: #b3b3b3;
$pale-blue: theme-color("primary");
$primary: theme-color("dark");
$red: theme-color("danger");
$white: theme-color("white");

$border: 4px;
$imgSize: 83px;

$breakPoint: "sm";

.Steps__Wrapper {
  display: block;
}
.Steps {
  position: relative;
  display: flex;
  padding: 0;
  list-style: none;
  width: inherit;
}

.Step {
  position: relative;
  font-size: 20px;
  flex: 1;

  &::before {
    content: "";
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, $breakPoint)) {
      margin-top: $imgSize / 2 - $border / 2;
      margin-left: $imgSize;
    }
    @media (max-width: map-get($grid-breakpoints, $breakPoint)) {
      margin-top: $imgSize / 4 - $border / 4;
      margin-left: $imgSize/2;
    }
    height: $border;
    background-color: $gray;
    position: absolute;
  }
  &__Milestone {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-gray;
    border: $border solid $gray;
    position: relative;
    bottom: 10px;
    position: relative;
  }
}

.Step__Completed {
  &::before {
    background: $pale-blue;
  }
  .Step__Milestone {
    background: $secondary;
    color: $white;
    border-color: $secondary;
  }
}

.Step__Invalid {
  .Step__Milestone {
    color: $red;
    border: $border solid $red;
  }
  .Step__Label {
    font-weight: bolder;
  }
}

.Step__Current {
  .Step__Milestone {
    color: $pale-blue;
    border-color: $secondary;
  }
  .Step__Label {
    font-weight: bolder;
  }
}

.Step__Label {
  color: $pale-blue;
  margin-right: $spacer * 0.5;
  font-family: "Roboto Condensed";
  @media (max-width: map-get($grid-breakpoints, $breakPoint)) {
    display: none;
  }
}

// .Step__Invalid .Step__Label,
// .Step__Completed .Step__Label,
// .Step__Current .Step__Label {
//   font-family: "Roboto Regular";
// }

.Step__Last::before {
  content: "";
  display: none;
}

.ImageIcon {
  @media (min-width: map-get($grid-breakpoints, $breakPoint)) {
    width: $imgSize;
    height: $imgSize;
  }
  @media (max-width: map-get($grid-breakpoints, $breakPoint)) {
    width: $imgSize / 2;
    height: $imgSize / 2;
  }
}
